import React from "react";
import { useEffect, useState, useRef, useContext } from "react";
import "../../css/renderSmall.css";
import { HiBadgeCheck, HiPrinter } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import def from "../../assets/images/default.jpg";
import { UpdateOrderItems } from "../../store/actions/kitchenAction";
import { toast } from "react-toastify";
import { UpdateOrderItemsQuantity } from "../../store/actions/kitchenAction";
import { Modal } from "react-bootstrap";
import { AiFillCopyrightCircle } from "react-icons/ai";
import notification from "../../assets/sound/film-countdown-88717.mp3";
import { SocketContext } from '../../context/socket.context';
import { useReactToPrint } from "react-to-print";
import { OrderItemPrint } from "./OrderItemPrint"

const RenderSmall = (props) => {
  const socket = useContext(SocketContext)
  // console.log(props.started + 30 * 60 * 1000, "me hu sabse badi jad");
  const audioPlayer = useRef(null);
  const componentref = useRef();
  const [currentTime, setCurrentTime] = useState(new Date());
  function playAudio() {
    // audioPlayer.current.play();
    props.playDelayedAudio()
  }

  const page = useSelector((state) => state.page);
  const [dangerClass, setDanger] = useState("");
  const [quantity, setQuantity] = useState(
    props.orderItem.quantity_completed
      ? props.orderItem.quantity_completed
      : props.orderItem.quantity
  );
  const [done, setDone] = useState(false);
  const doneRef = useRef(done);
  const dispatch = useDispatch();
  const employee_info = useSelector((state) => state.emp_info);
  const kitchenOrder = useSelector((state) => state.kitchen);
  const AllProducts = useSelector((state) => state.product);
  const itemDetails = AllProducts[props.orderItem.product_id];
  // MOdal popup vars
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // MOdal popup vars end
  doneRef.current = done;
  useEffect(() => {
    const time = props.orderItem.prepare_time;
    if (props.started && time) {
      const getTotSeconds = (time) => {
        let currDate = new Date();
        const diff = () => {
          if (props.started === true) return 0;
          else return Math.abs((currDate.getTime() - props.started) / 1000);
        };
        let hours = 0;
        let minutes = 0;
        let sec = 0;
        if (time.includes("h")) {
          hours = time.split("h")[0] * 60 * 60;
          if (time.includes("m")) {
            minutes = time.split("h")[1].split("m")[0] * 60;
            sec = time.split("m")[1].split("s")[0];
          } else {
            sec = time.split("h")[1].split("s")[0];
          }
        } else {
          if (time.includes("m")) {
            minutes = time.split("m")[0] * 60;
            sec = time.split("m")[1].split("s")[0];
            if (sec === "") {
              sec = 0;
            }
          } else {
            sec = time.split("s")[0];
          }
        }
        if (
          (parseInt(hours) + parseInt(minutes) + parseInt(sec)) *
            props.orderItem.quantity -
            diff() >
          0
        ) {
          return (
            (parseInt(hours) + parseInt(minutes) + parseInt(sec)) *
              props.orderItem.quantity -
            diff()
          );
        } else {
          return 0;
        }
      };

      let timer = setTimeout(() => {
        if (
          doneRef.current === true ||
          props.orderItem.status === "Completed"
        ) {
          setDanger(" ");
        } else {
          setDanger("deadline");
          // if (props.started + 30 * 60 * 1000 > currentTime) {
          playAudio();
          // }
        }
      }, getTotSeconds(time) * 1000);
      return () => clearTimeout(timer);
    }
  }, [props.started, kitchenOrder, page]);

  // useEffect(() => {
  //   if (props.order.status != "Completed" && dangerClass === "deadline") {
  //     playAudio();
  //   }
  // }, []);

  const description = () => {
    // console.log(props.orderItem)
    if (props.orderItem.add_ons) {
      return Object.keys(props.orderItem.add_ons).map((key, i) => {
        if (Array.isArray(props.orderItem.add_ons[key])) {
          var output = "";
          for (var j = 0; j < props.orderItem.add_ons[key].length; j++) {
            output = output + props.orderItem.add_ons[key][j]["value"] + " ,";
          }
          return (
            <span key={i}>
              {key}:{output.substring(0, output.length - 2)} ,
            </span>
          );
        } else {
          return (
            <span key={i}>
              {key}:{props.orderItem.add_ons[key]} ,
            </span>
          );
        }
      });
    }
  };
  const clickQuantity = (e) => {
    console.log('ready clicked');
    if (props.started) {
      if (quantity > 0) {
        if (quantity == 1) {
          const completedTime = () => {
            const date_future = new Date().getTime();
            var delta = Math.abs(date_future - props.started) / 1000;
            const days = Math.floor(delta / 86400);
            delta -= days * 86400;
            const hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;
            const minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;
            const seconds = Math.floor(delta % 60);
            if (days === 0 && hours === 0) {
              return `${minutes}m${seconds}s`;
            }
            if (days === 0 && hours > 0) {
              return `${hours}h${minutes}m${seconds}s`;
            }
            if (days > 0 && hours === 0) {
              return `${days}d${hours}h${minutes}m${seconds}s`;
            } else {
              return `${days}d${hours}h${minutes}m${seconds}s`;
            }
          };
          const compTime = completedTime();
          console.log('time calculated. quantity == 1. Dispatching UpdateOrderItems...')
          // dispatch(
          //   UpdateOrderItems(
          //     page,
          //     employee_info.employeeinfo.data.branch_id,
          //     props.orderItem.order_items_id,
          //     compTime,
          //     employee_info.employeeinfo.data.employee_id,
          //     props.orderItem.quantity,
          //     employee_info.employeeinfo.data.token,
          //     employee_info.role
          //   )
          // );

          socket.emit("update_order_items", {
            branch: employee_info.employeeinfo.data.branch_id,
            order_id: props.order_id,
            start_time: props.start_time,
            data: {
              "order_items_id": props.orderItem.order_items_id,
              "status": "Completed",
              "completed_time": compTime,
              "prepared_by": employee_info.employeeinfo.data.employee_id,
              "quantity_completed": props.orderItem.quantity
            }
          })

          setDone(true);
          setDanger(" ");
        } else {
          setQuantity(quantity - 1);
          console.log('time calculated. quantity > 1. Dispatching UpdateOrderItemsQuantity...')
          // dispatch(
          //   UpdateOrderItemsQuantity(
          //     page,
          //     employee_info.employeeinfo.data.branch_id,
          //     props.orderItem.order_items_id,
          //     quantity - 1,
          //     employee_info.employeeinfo.data.token,
          //     employee_info.role
          //   )
          // );
          socket.emit("update_order_items", {
            branch: employee_info.employeeinfo.data.branch_id,
            order_id: props.order_id,
            start_time: props.start_time,
            data: {
              "order_items_id": props.orderItem.order_items_id,
              "status": "Preparing",
              "completed_time": null,
              "prepared_by": null,
              "quantity_completed": quantity - 1
            }
          })
        }
      }
    } else {
      toast.error("Please start the Order first", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    e.stopPropagation();
    return null;
  };

  const printKT = (e) =>{
    e.stopPropagation();
    print()
  } 
  
  const print = useReactToPrint({
    content: () => componentref.current,
  });

  const kitchenReady = () => {
    if (props.orderItem.food_type === "Veg") {
      if (
        props.orderItem.quantity > 0 &&
        props.orderItem.status !== "Completed"
      ) {
        return (
          <div style={{marginTop: '5px'}}>
            <div className="btn btn-success btn-sm quan  mt-1">
              Qty:{props.orderItem.quantity}
            </div>
            <div className="btn btn-success btn-sm ms-2 quan mt-1">
              {quantity}
            </div>
            <div
              className="btn btn-success btn-sm ms-2 quan mt-1"
              onClick={clickQuantity}
            >
              Ready?
            </div>
            <div
              className="btn btn-outline-success btn-sm ms-2 quan mt-1" 
              // style={{float: 'right'}}
              onClick={printKT}
            >
              <HiPrinter/>
            </div>
            <div className="visually-hidden-focusable p-1">
              
              <OrderItemPrint
                componentref={componentref}
                order={props}
              />
              
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="btn btn-success btn-sm quan mt-1">
              Qty:{props.orderItem.quantity}
            </div>
            <div className="orderItem-done" style={{ color: "#578d74" }}>
              <HiBadgeCheck />
            </div>
          </div>
        );
      }
    } else {
      if (
        props.orderItem.quantity > 0 &&
        props.orderItem.status !== "Completed"
      ) {
        return (
          <div style={{marginTop: '5px'}}>
            <div className="btn btn-danger btn-sm quan mt-1">
              Qty:{props.orderItem.quantity}
            </div>
            <div className="btn btn-danger btn-sm ms-2 quan mt-1">
              {quantity}
            </div>
            <div
              className="btn btn-danger btn-sm ms-2 quan mt-1"
              onClick={clickQuantity}
            >
              Ready?
            </div>
            <div
              className="btn btn-outline-success btn-sm ms-2 quan mt-1" 
              // style={{float: 'right'}}
              onClick={printKT}
            >
              <HiPrinter/>
            </div>
            <div className="visually-hidden-focusable p-1">
              
              <OrderItemPrint
                componentref={componentref}
                order={props}
              />
              
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="btn btn-danger btn-sm quan mt-1">
              Qty:{props.orderItem.quantity}
            </div>
            <div className="orderItem-done" style={{ color: "#cc3342d1" }}>
              <HiBadgeCheck />
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div key={props.newId} style={{ position: "relative" }}>
      <div className="d-flex mt-2" onClick={handleShow}>
        <div className="w-25 d-flex justify-content-start align-items-start p-1">
          <img
            className={`DoneImage ${dangerClass}`}
            style={{ borderRadius: "10px" }}
            src={itemDetails ? itemDetails.product_list.card_img : def}
            alt={props.newId + "IMAGE"}
          ></img>
        </div>
        <audio ref={audioPlayer} src={notification} />
        <div className="w-75 ready-small-content">
          <div className="order-card-title ">
            {props.orderItem.product_name} {`(${props.orderItem.order_sku})`}
          </div>
          {props.orderItem.food_type === "Veg" ? (
            <div className="foodSign vegSign">
              <div className="circle-veg"></div>
            </div>
          ) : (
            <div className="foodSign nonvegSign">
              <div className="circle-nonveg"></div>
            </div>
          )}
          <div className="order-card-text ">{description()}</div>
          {props.orderItem.comment ? (
            <AiFillCopyrightCircle className="commentSymbol" />
          ) : (
            <></>
          )}
          {kitchenReady()}
        </div>
      </div>
      <>
        <Modal
          className="remove-border"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header
            className=" bg-secondary text-accent remove-border"
            closeButton
          >
            <Modal.Title>Comments</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" bg-accent text-secondary remove-border">
            {props.orderItem.comment ? (
              <span>{props.orderItem.comment}</span>
            ) : (
              <span>No comments</span>
            )}
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default RenderSmall;
