import { v4 as uuid } from "uuid";
import { Accordion } from "react-bootstrap";
import Stopwatch from "../Stopwatch";
import { useState, useEffect, useContext } from "react";
import RenderSmall from "./RenderSmall";
import RenderSmallDisabled from "./RenderSmallDisabled";
import { useDispatch, useSelector } from "react-redux";
import "../../css/active.css";
import { FaUser } from "react-icons/fa";
import { toast } from "react-toastify";
// import { completeBaristaOrders } from "../../store/actions/baristaAction";
import { sendMessage } from "../../store/actions/serverAction";
import { SocketContext } from '../../context/socket.context';


const BigCard = ({ role, index, item, playDelayedAudio }) => {
  const socket = useContext(SocketContext)
  const OrderId = item.order_id;
  const AllProducts = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const emp_info = useSelector((state) => state.emp_info);
  const [phone, setphone] = useState("displayNone");
  const [started, setStarted] = useState(item.start_time);
  const kitchenOrder = useSelector((state) => state.barista);
  const [contactButton, setContactButton] = useState("contact-button");
  const page = useSelector((state) => state.page);
  const getTime = (time) => {
    const datec = time.split("T")[0];
    const timec = time.split("T")[1].split("Z")[0].slice(0, -4);
    return `${datec} ${timec}`;
  };

  const TimeOnCard = new Date(item.createdAt).toLocaleString();

  useEffect(() => {
    setStarted(item.start_time);
    // const completedTime = () => {
    //   const date_future = new Date().getTime();
    //   var delta = Math.abs(date_future - item.start_time) / 1000;
    //   const days = Math.floor(delta / 86400);
    //   delta -= days * 86400;
    //   const hours = Math.floor(delta / 3600) % 24;
    //   delta -= hours * 3600;
    //   const minutes = Math.floor(delta / 60) % 60;
    //   delta -= minutes * 60;
    //   const seconds = Math.floor(delta % 60);
    //   return `${days}d${hours}h${minutes}m${seconds}s`;
    // };
    // let x = 0;
    // let y = 0;
    // for (var i = 0; i < item.barista.length; i++) {
    //   if (item.barista[i].status === "Completed") {
    //     x = x + 1;
    //   }
    // }
    // for (var i = 0; i < item.kitchen.length; i++) {
    //   if (item.kitchen[i].status === "Completed") {
    //     y = y + 1;
    //   }
    // }
    // if (x + y === item.barista.length + item.kitchen.length) {
    //   if (item.order_type === "Take Away") {
    //     dispatch(sendMessage(item.customer_no));
    //   }
    //   dispatch(
    //     completeBaristaOrders(
    //       page,
    //       emp_info.employeeinfo.data.branch_id,
    //       item.order_id,
    //       item.start,
    //       completedTime(),
    //       emp_info.employeeinfo.data.token,
    //       emp_info.role
    //     )
    //   );
    //   toast.success(`Order # ${item.order_id} Ready`, {
    //     position: "top-right",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // }
  }, [kitchenOrder]);
  const renderSmallDis = (item) => {
    return item.kitchen.map((orderItem, orderIndex) => {
      const newId = uuid().slice(0, 8);

      return (
        <RenderSmallDisabled key={newId} newId={newId} orderItem={orderItem} item={item} />
      );
    });
  };
  const renderSmall = (item) => {
    if (AllProducts !== "") {
      var id = item.order_id
      var start_time = item.start_time
      return item.barista.map((orderItem, orderIndex) => {
        const newId = uuid().slice(0, 8);

        return (
          <RenderSmall
            started={started}
            role={role}
            key={newId}
            newId={newId}
            orderItem={orderItem}
            order_id={id}
            start_time={start_time}
            playDelayedAudio={playDelayedAudio}
            item={item}
          />
        );
      });
    }
  };
  const ContactFunc = () => {
    setContactButton("displayNone");
    setphone("contact-info");
  };
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-3">
      <div
        className="card outer-scroll"
        style={{
          width: "100%",
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: started ? "0 0 10px #578d74" : "",
          border: started ? "4px solid #578d74" : "",
          height: '100%'
        }}
      >
        <div
          className="card-body "
          style={{
            boxShadow: started ? "0 0 10px #578d74" : "",
            display: 'grid'
          }}
        >
          <div className="row mb-4">
            <div className="col-8  orderNumber">
              Order#{item.order_id}
              <span className="orderTime"> : {item["Customer-Name"]}</span>
              <div
                className="col-4 orderNumber prep-view"
                style={{ whiteSpace: "nowrap" }}
              >
                {" "}
                <span style={{ color: "red" }}>
                  <Stopwatch
                    phone={phone}
                    OrderId={OrderId}
                    offset={item.start_time}
                    started={started}
                    setStarted={setStarted}
                    kitLen={item.kitchen.length}
                    barLen={item.barista.length}
                    orderFrom={item.order_from}
                    pickTime={item.pick_time}
                  />
                </span>
              </div>
              <div className="orderTime mt-1">
                {item.pick_time? 
                  <div>Pick up time: {new Date(item.pick_time).toLocaleString()}</div>
                  : ""
                }
                <div>Comments: {item.comment}</div> {TimeOnCard}
                <div style={{fontWeight: "bold", color: 'black'}}>{item.customer_name}</div>
              </div>
              <div
                className={`btn  btn-sm ${contactButton}`}
                onClick={() => ContactFunc()}
              >
                View Contact
              </div>
              <div className={`${phone}`}>{item.customer_no} </div>
            </div>
            <div className="col-4 d-flex justify-content-end ">
              <div className="userImage">
                <FaUser />
              </div>
              {item.order_type === "Take Away" ? (
                <div className="bookmarkRibbon bookmarkRibbonTA">
                  <span>T</span>
                </div>
              ) : (
                <div className="bookmarkRibbon bookmarkRibbonDI">
                  <span>D</span>
                </div>
              )}
            </div>
          </div>
          <div
            className="innerScroll margin-top-scroll"
            style={{ overflowY: "auto" }}
          >
            {renderSmall(item)}
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                {item.kitchen.length == 0 ? 
                '' 
                :
                <Accordion.Header>View Kitchen</Accordion.Header>
                }
                
                <Accordion.Body>{renderSmallDis(item)}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BigCard;
