import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ActiveBarista from './components/Barista/ActiveBarista';
import ActiveKitchen from './components/Kitchen/ActiveKitchen';
import KitchenReady from './components/Kitchen/Ready';
import BaristaReady from './components/Barista/Ready';
import Login from './components/Authentication/Login';
import Protected from './Protected';
import { ToastContainer } from "react-toastify"
import CompletedOrders from './components/Server/CompletedOrders';
import Delivered from './components/Server/Delivered';
import "./app.css"
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import PageNotFound from './components/PageNotFound';
import { useDispatch, useSelector } from 'react-redux';
// import { SocketContextKitchen, socketKitchen } from "./context/socket.kitchen.context";
// import { SocketContextBarista, socketBarista } from "./context/socket.barista.context";
import {SocketContext} from "./context/socket.context"
import {io} from 'socket.io-client'

function App() {
  // const [loading, setLoading] = useState(false);

  // const socket = io.connect('http://localhost:3001/')

  // const dispatch = useDispatch();
    // const ReadyOrder = useSelector(state => state.readyOrders);
    // const page = useSelector(state => state.readyPage)
    const emp_info = useSelector(state => state.emp_info)
    
    if(emp_info["status"] == "success"){
        
        if(emp_info.role == 'kitchen'){
            console.log('emp_info.role', emp_info.role)
            const socket = io(process.env.REACT_APP_API_URL + 'kitchen')
            return (
              <SocketContext.Provider value={socket}>
                <Router>
                  <div className="App">
                    <div className='inner-App'>
                        <Routes>
                          <Route path="*" element={<Login />} />
                          <Route path="/" element={<Login />} />

                          <Route path="/kitchen" element={
                            <Protected>
                                <ActiveKitchen /> 
                            </Protected>
                          } />
                          <Route path="/kitchen/ready" element={
                          
                            <Protected>
                                <KitchenReady />
                            </Protected>
                          } />
                        </Routes>
                    </div>
                  </div>
                  <ToastContainer />
                </Router>
              </SocketContext.Provider>
              
            )
        }
        else if(emp_info.role == 'barista'){
            console.log('emp_info.role', emp_info.role)
            const socket = io(process.env.REACT_APP_API_URL + 'barista')
            return (
              <SocketContext.Provider value={socket} >
                <Router>
                  <div className="App">
                    <div className='inner-App'>
                        <Routes>
                          <Route path="*" element={<Login />} />
                          <Route path="/" element={<Login />} />

                          
                          <Route path="/barista" element={
                            <Protected  >
                                <ActiveBarista />
                            </Protected>
                          } />
                          
                          <Route path="/barista/ready" element={<Protected  ><BaristaReady /></Protected>} />

                        </Routes>
                      
                    </div>
                  </div>
                  <ToastContainer />
                </Router>
              </SocketContext.Provider>
            )
    
        }else if(emp_info.role == 'server'){
            console.log('emp_info.role', emp_info.role)
            const socket = io(process.env.REACT_APP_API_URL + 'server')
            return (
              <SocketContext.Provider value={socket}>
                <Router>
                  <div className="App">
                    <div className='inner-App'>
                        <Routes>
                          <Route path="*" element={<Login />} />
                          <Route path="/" element={<Login />} />

                         
                          <Route path="/server" element={<Protected  ><CompletedOrders /> </Protected>} />
                          <Route path="/server/delivered" element={<Protected  ><Delivered /></Protected>} />
                          
                        </Routes>
                      
                    </div>
                  </div>
                  <ToastContainer />
                </Router>
              </SocketContext.Provider>
            )
        }
        else{
            console.log('empty context 1')
            return (
              <Router>
                <div className="App">
                  <div className='inner-App'>
                      <Routes>
                        <Route path="*" element={<Login />} />
                        <Route path="/" element={<Login />} />

                        
                      </Routes>
                    
                  </div>
                </div>
                <ToastContainer />
              </Router>
            )
        }
    }else{
        console.log('empty context 2')
        return (
          <Router>
                <div className="App">
                  <div className='inner-App'>
                      <Routes>
                        <Route path="*" element={<Login />} />
                        <Route path="/" element={<Login />} />

                      </Routes>
                    
                  </div>
                </div>
                <ToastContainer />
              </Router>
        )
    }
  
  // return (
    
  //   <Router>
  //     <div className="App">
  //       <div className='inner-App'>
  //           <Routes>
  //             <Route path="*" element={<Login />} />
  //             <Route path="/" element={<Login />} />

  //             <Route path="/kitchen" element={
  //               <Protected>
  //                   <ActiveKitchen /> 
  //               </Protected>
  //             } />
  //             <Route path="/barista" element={
  //               <Protected  >
  //                   <ActiveBarista />
                  
  //               </Protected>
  //             } />
  //             <Route path="/completed" element={<Protected  ><CompletedOrders /> </Protected>} />
  //             <Route path="/delivered" element={<Protected  ><Delivered /></Protected>} />
  //             <Route path="/kitchen/ready" element={
              
  //               <Protected>
  //                   <KitchenReady />
  //               </Protected>
  //             } />
  //             <Route path="/barista/ready" element={<Protected  ><BaristaReady /></Protected>} />

  //           </Routes>
          
  //       </div>
  //     </div>
  //     <ToastContainer />
  //   </Router>
  // );



}

export default App;
