import React, { useState } from "react";
import { useSelector } from "react-redux";


export const OrderItemPrintDisabled = ({ order, componentref }) => {
    

    const description = () => {
        // console.log(order.orderItem)
        if (order.orderItem.add_ons) {
            return Object.keys(order.orderItem.add_ons).map((key, i) => {
                if (Array.isArray(order.orderItem.add_ons[key])) {
                    var output = "";
                    for (var j = 0; j < order.orderItem.add_ons[key].length; j++) {
                        output = output + order.orderItem.add_ons[key][j]["value"] + " ,";
                    }
                    return (
                        <span key={i}>
                            {output.substring(0, output.length - 2)} ,
                        </span>
                    );
                } else {
                    return (
                        <span key={i}>
                            {order.orderItem.add_ons[key]} ,
                        </span>
                    );
                }
            });
        }
    };


    return (
        <>
            <div ref={componentref} className="px-1">
                {/* <div className="flex flex-row  justify-between items-center my-4">
                    <NavIcon className="w-20 h-20 py-2 px-2 " />
                    <div >
                        <p className="text-sm">{orderData?.order_id}</p>
                        <p className="text-sm">{orderData?.branch_name}</p>
                        <Moment format="MMM D, YYYY hh:mm a" className="text-sm">{orderData?.createdAt}</Moment>
                    </div>
                </div>
                <div className="flex justify-end">
                    <h2 className="font-bold text-xs">Status: &nbsp; </h2>
                    <h2 className="capitalize text-xs">{orderData.status}</h2>
                </div>
                <div className="flex justify-end">
                    <h2 className="font-bold text-xs">Cashier: &nbsp; </h2>
                    <h2 className="capitalize text-xs">{userDataList?.full_name}</h2>
                </div>
                <div className="flex justify-end">
                    <h2 className="font-bold text-xs">Customer Name: &nbsp; </h2>
                    <h2 className="capitalize text-xs">{orderData.customer_name}</h2>
                </div>
                {
                    orderData.customer_name !== "Guest" &&
                    <div className="flex justify-end">
                        <h2 className="font-bold text-xs">Customer No.: &nbsp; </h2>
                        <h2 className="capitalize text-xs">{orderData?.customer_no}</h2>
                    </div>
                }

                <div className="flex flex-row w-full border-b-2 border-dotted border-black py-2 my-1 text-sm ">
                    <div className="flex basis-1/2 font-bold justify-start">ITEM</div>
                    <div className="flex basis-1/4 font-bold justify-center">QTY</div>
                    <div className="flex basis-1/4 font-bold justify-end">SUBTOTAL</div>
                </div>
                {order.map((order_item, i) => {
                    let adds = objectToArray(order_item.add_ons);
                    return (
                        <div className="flex flex-row text-xs text-gray-900 " key={i}>
                            <div className="basis-1/2">
                                <div className="mb-1">
                                    <h2 className=" text-gray-900 capitalize  cursor-pointer font-bold">
                                        {toUpper(order_item.product_name)}
                                    </h2>
                                    <div className=" flex flex-row  text-gray-900  leading-tight  capitalize">
                                        {adds.map((addon, index) => <span className="mr-1" key={index}>{addon.desc}{(index !== (adds.length - 1)) && ', '}</span>)}{order_item.comment === '' ? '' : ', ' + order_item.comment}
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="flex basis-1/4 justify-center font-bold">
                                {order_item?.quantity || 1}
                            </div>
                            <div className="flex basis-1/4 justify-end font-bold">
                                ₹{Math.round(((order_item?.price + order_item?.add_ons_price) * order_item?.quantity) * 100) / 100}
                            </div>
                        </div>
                    );
                })} */}
                <div className="flex-col py-2 my-2 ">
                    <div className="d-flex flex-row justify-content-between font-thin text-xs">
                        <div className="text-gray-900 font-bold">{order.item.customer_name}</div>
                        <div className="font-bold">
                            {order.item.order_id}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between font-thin text-xs">
                        <div className="text-gray-900 font-bold">{order.item.order_type}</div>
                        <div className="font-bold">
                            {order.item.comment}
                        </div>
                    </div>
                    {/* {orderData?.membership_discount && (
                        <div className="flex flex-row justify-between font-thin text-xs">
                            <div className="text-gray-900 font-bold uppercase">Membership discount</div>
                            <div className="font-bold">
                                ₹{Math.round(orderData?.membership_discount * 100) / 100}
                            </div>
                        </div>
                    )} */}
                    <div className="d-flex flex-row justify-content-between font-thin text-xs">
                        <div className="text-gray-900 font-bold">{order.orderItem.product_name}-{description()}</div>
                        {/* <div className="font-bold">
                            ₹{Math.round(orderData?.sgst * 100) / 100}
                        </div> */}
                    </div>
                    {/* <div className="d-flex flex-row justify-content-between font-thin text-xs">
                        <div className="text-gray-900 font-bold"></div>
                        <div className="font-bold">
                            ₹{Math.round(orderData?.sgst * 100) / 100}
                        </div>
                    </div> */}
                    {/* <div className="flex flex-row justify-between font-thin text-xs">
                        <div className="text-gray-900 font-bold">CGST</div>
                        <div className="font-bold">
                            ₹{Math.round(orderData?.cgst * 100) / 100}
                        </div>
                    </div>
                    {orderData?.order_type === "Delivery" && (
                        <div className="flex flex-row justify-between font-thin text-xs">
                            <div className="text-gray-900 font-bold">Delivery Charges</div>
                            <div className="font-bold">
                                ₹{Math.round(orderData?.delivery_charges * 100) / 100}
                            </div>
                        </div>
                    )}
                    <div className="flex flex-row justify-between font-thin text-xs">
                        <div className="text-gray-900 font-bold">GRAND TOTAL</div>
                        <div className="font-bold">
                            ₹
                            {orderData?.payment_method === "card + cash"
                                ? Math.round(orderData?.card_amount * 100) / 100 +
                                Math.round((orderData?.cash_amount || 0) * 100) / 100
                                : Math.round(orderData?.paid_price * 100) / 100}
                        </div>
                    </div>
                    <div className="flex flex-row justify-between font-thin text-xs border-t-2 border-dotted border-black pt-2 mt-1">
                        <div className="text-gray-900 font-bold">PAYMENT METHOD</div>
                        <div className="font-bold uppercase">
                            {orderData?.payment_method}{" "}
                        </div>
                    </div>
                    {orderData?.payment_method === "card" && (
                        <div className="flex flex-row justify-between font-thin text-xs">
                            <div className="text-gray-900 font-bold">CARD AMOUNT</div>
                            <div className="font-bold">
                                ₹{Math.round(orderData?.card_amount * 100) / 100 || 0}{" "}
                            </div>
                        </div>
                    )}
                    {orderData?.payment_method === "wallet" && (
                        <div className="flex flex-row justify-between font-thin text-xs">
                            <div className="text-gray-900 font-bold">WALLET AMOUNT</div>
                            <div className="font-bold">
                                ₹{Math.round(orderData?.wallet_amount * 100) / 100 || 0}{" "}
                            </div>
                        </div>
                    )}
                    {orderData?.payment_method === "cash" && (
                        <div className="flex flex-row justify-between font-thin text-xs">
                            <div className="text-gray-900 font-bold">CASH AMOUNT</div>
                            <div className="font-bold">
                                ₹{Math.round(orderData?.cash_amount * 100) / 100 || 0}{" "}
                            </div>
                        </div>
                    )}
                    {orderData?.payment_method === "card + cash" && (
                        <div>
                            <div className="flex flex-row justify-between font-thin text-xs">
                                <div className="text-gray-900 font-bold">CASH AMOUNT</div>
                                <div className="font-bold">
                                    ₹{Math.round(orderData?.cash_amount * 100) / 100 || 0}{" "}
                                </div>
                            </div>
                            <div className="flex flex-row justify-between font-thin text-xs">
                                <div className="text-gray-900 font-bold">CARD AMOUNT</div>
                                <div className="font-bold">
                                    ₹{Math.round(orderData?.card_amount * 100) / 100 || 0}{" "}
                                </div>
                            </div>
                        </div>
                    )}
                    {orderData?.payment_method === "wallet + cash" && (
                        <div>
                            <div className="flex flex-row justify-between font-thin text-xs">
                                <div className="text-gray-900 font-bold">WALLET AMOUNT</div>
                                <div className="font-bold">
                                    ₹{Math.round(orderData?.wallet_amount * 100) / 100 || 0}{" "}
                                </div>
                            </div>
                            <div className="flex flex-row justify-between font-thin text-xs">
                                <div className="text-gray-900 font-bold">CASH AMOUNT</div>
                                <div className="font-bold">
                                    ₹{Math.round(orderData?.cash_amount * 100) / 100 || 0}{" "}
                                </div>
                            </div>
                        </div>
                    )}
                    {orderData?.payment_method === "wallet + card" && (
                        <div>
                            <div className="flex flex-row justify-between font-thin text-xs">
                                <div className="text-gray-900 font-bold">WALLET AMOUNT</div>
                                <div className="font-bold">
                                    ₹{Math.round(orderData?.wallet_amount * 100) / 100 || 0}{" "}
                                </div>
                            </div>
                            <div className="flex flex-row justify-between font-thin text-xs">
                                <div className="text-gray-900 font-bold">CARD AMOUNT</div>
                                <div className="font-bold">
                                    ₹{Math.round(orderData?.card_amount * 100) / 100 || 0}{" "}
                                </div>
                            </div>
                        </div>
                    )}
                    {orderData?.payment_method === "cash" && (
                        <>
                            <div className="flex flex-row justify-between font-thin text-xs">
                                <div className="text-gray-900 font-bold">CHANGE</div>
                                <div className="font-bold">
                                    ₹{Math.round(orderData.change * 100) / 100}{" "}
                                </div>
                            </div>
                        </>
                    )}
                    <div className="flex flex-row justify-between font-thin text-xs border-t-2 border-dotted border-black pt-2 mt-1">
                        <div className="font-bold">{orderData?.comment} </div>
                    </div>
                    <div className="flex flex-row justify-between font-thin text-xs">
                        <div className="text-gray-900 font-bold">ORDER TYPE</div>
                        <div className="font-bold">{orderData?.order_type} </div>
                    </div> */}
                </div>
                {/* <div className="font-bold self-center my-2 grid place-content-center">
                    -------- ****** --------
                </div>
                <div className="text-xs self-center text-center">
                    <h6 className="font-bold mb-0">
                        Heebee coffee private limited
                    </h6>
                    <h6 className="font-bold mb-1">
                        <small>Subject to Ludhiana Jurisdiction</small>
                    </h6>
                    <h6>
                        <span className="font-bold">GSTIN </span>03AAFCH0428K1ZV
                    </h6>
                    <br />
                    <div className="text-xs m-2 self-center p-2 text-center font-extrabold">
                        <span>*</span>Thanks for choosing us! We appreciate the opportunity
                        to serve you.<span>*</span>
                    </div>
                </div> */}
            </div>
        </>
    );
};