import React from 'react';
import { useSelector } from 'react-redux';

const FooterBarista = () => {
    const baristaOrder=useSelector(state=> state.barista);
    
   
    return (
        <div className='container-fluid  mt-4 foot' style={{ backgroundColor: "#d1cbb9" }}>
            <div className='row' style={{ height: "90px" }}>
                <div className='w-100 h-100 d-flex justify-content-end align-items-center'>
                    <div className='btn btn-secondary '>Total Orders:{baristaOrder.data.total_orders ? baristaOrder.data.total_orders : 0}</div>
                    
                </div>
            </div>
        </div>
    );
};

export default FooterBarista;